

.App {
  text-align: center;
}

.text-underline{
  color: blue !important;
  position: relative !important;
  
}

.text-underline::before {
  content: '';
     position: absolute !important;
     width: 30%;
     height: 4px;
     border-radius: 4px;
     background-color: #18272F;
     bottom: 0 !important;
     left: 0 !important;
     transform-origin: right !important;
     transform: scaleX(0) !important;
     transition: transform .3s ease-in-out;
}

.text-underline:hover::before {
  transform-origin: left !important;
  transform: scaleX(1) !important;
}

button {
  transition: all 0.5s;
  border: none !important;
}

/* .ant-btn-primary {
  background-color: #38598b !important;
  color: white !important;
} */

.btn-warning {
  line-height: 1.5715;
  position: relative !important;
  display: inline-block !important;
  font-weight: 400;
  white-space: nowrap !important;
  text-align: center !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  user-select: none !important;
  touch-action: manipulation !important;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px !important;
  color: white !important;
  background-color: #ebad26 !important;
}

.btn-secondary {
  background-color: #ffca00 !important;
  color: #14213D !important;
}

.btn-danger {
  line-height: 1.5715;
  position: relative !important;
  display: inline-block !important;
  font-weight: 400;
  white-space: nowrap !important;
  text-align: center !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  user-select: none !important;
  touch-action: manipulation !important;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px !important;
  color: white !important;
  background-color: #eb2632 !important;
}

button:hover {
box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.Login {
  /* background-image: url('./assets/img/5739655.jpg'); */
 background-color: #18272F;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.auth-block {
  position: relative;
  height: 60%;
  top: 0;
  background-color: #fff;
  padding: 30px;
  margin-top: auto;
  margin-bottom: auto;
  /* margin: 10% 0; */
  max-width: 400px;
  width: 90%;
  /* position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%); */
}

.form-block {
  background-color: #fff;
  padding: 30px;
  margin: auto;
  max-width: 500px;
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
     rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

/* 
@media screen and (max-width: 520px) {
 .ant-layout-header .trigger {
   display: none
 }
} */


.ant-layout-header .trigger {
  font-size: 26px;
  transition: 0.3s all;
  cursor: pointer;
}
.ant-layout-header .trigger:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 458px) {

  .ant-layout-content {
    padding: 12px !important;
  }
  
  .ant-layout-header .ant-space-item .ant-badge {
     display: none !important;
  }
     .ant-layout-header .ant-space-item {
        font-size: 12px !important;
     }

}

.dashboard-box {
  border-radius: 20px;
  background-color: white;

}

.main-shadow {
  /* box-shadow: 0 0 3px #ccc; */
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.second-shadow {
  /* box-shadow: 0 0 3px #ccc; */

  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}
.second-shadow2 {
  /* box-shadow: 0 0 3px #ccc; */

  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

/* table */

/* Custom styles for the table header */
.ant-table-thead > tr > th {
  background-color: white !important ; /* Change to your desired color */
  color: white; /* Change to your desired text color */
}

/* Custom styles for the table body */
.ant-table-tbody > tr > td {
 
  color: black; /* Change to your desired text color */
}

/* Optional: Add hover effects for the table rows */
.ant-table-tbody > tr:hover > td {
  background-color: #ddd; /* Change to your desired hover color */
}

/* table */

.site-layout .site-layout-background {
  /* background: #ffffff; */
  background: #FAFBFD;
  box-shadow: 0 0 3px #ccc;
  border-radius: 5px;
}

.site-layout .site-layout-background-right {
  background: #fff;
  box-shadow: 0 0 3px #ccc;
  border-radius: 5px;
  width: 500px;
  max-width: 500px;
}

.ant-layout-has-sider {
  padding: 15px !important;
}

.ant-layout-sider {
  border-radius: 10px !important;
 
  /* background: #f5f5f5f5 !important; */
  background: #f0f0f0 !important;
  box-shadow:  0 0 3px #ccc;

}

.ant-layout-sider-zero-width > * {
  opacity: 0 !important;
}

.ant-layout-sider-children {
  padding-top: 15px;
  padding-right: 20px;
  height: 95vh;
}

@media screen and (max-width: 520px) {
  .ant-layout-has-sider {
     padding: 0 !important;
  }
}

.ant-layout-header {
  margin: 0 10px !important;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
}

.anticon svg {
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.logo h3 {
  color: #047979 !important;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 100px;
}

.ant-menu-item-selected {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
  border: 0.5px solid black;
  color: black !important;
}


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  background-color: transparent !important;

  color: black !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected svg {
  color: black !important;
}

.ant-menu-item:hover {
  background-color: transparent !important;
  color: black !important;

}

.ant-menu-item:hover a {
  color: black !important;
  
}

.ant-menu {
  background: transparent !important;
  padding: 20px 0 !important;
  border-radius: 10px;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0 !important;
}

/* .ant-menu-root.ant-menu-inline {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 10px !important;
} */

.ant-menu-title-content a {
  color: black !important;
}



.ant-menu-item-selected::after {
  opacity: 0 !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

.ant-card-head-title svg {
  /* color: #f9d800 ; */
  font-size: 36px;
}

.ant-card {
  transition: all 0.5s ease;
  cursor: pointer;
}

/* .ant-card:hover {
  transform: scale(1.1);
} */

.summary-row {
  background-color: #7c73e6;
  color: white;
}

.summary-row:hover {
  background-color: #7c73e6;
  color: black;
}


.first-row {
  background-color: #ffc93c;
}

.drawer {
  width: 56px;
  height: 56px;
  padding: 0;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  cursor: pointer;
  display: inline-block;
  transition: 0.3s ease-out;
  font-size: 1.6rem;
  text-align: center;
  border-radius: 50%;
  border: none;
  background-color: #eb2632 !important;
  color: white;
  overflow: hidden;
}

.ant-drawer-title {
  font-size: 30px !important;
  color: #38598b !important;
  border-bottom: 3px blue !important;
}

.ant-drawer-title img svg {
  color: rgb(2, 2, 2) !important;
  border-bottom: 3px blue !important;
}

.ant-dropdown-menu {
  width: 300px;
}

.ant-dropdown-menu-item-icon {
  margin-right: 20px !important;
  font-size: 24px !important;
  margin-bottom: 15px !important;
}

.ant-result .anticon svg {
  font-size: 75px !important;
  width: 75px !important;
  height: 75px !important;
}

.ant-statistic-content {
  font-size: 16px !important;
}

.typeahead {
}
.typeahead-address-label {
  padding: 10px 10px 10px 0;
  display: inline-block;
}

.typeahead-input-wrap input {
  width: 100%;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}
.typeahead-input-hint {
  display: none;
}

.loading {
  background-color: transparent !important;
  height: 100vh !important;
}

/* .css-1kjun61 {
 width: 400px !important;

}


@media screen and (max-width: 520px) {
.css-1kjun61 {
   width: 250px !important;
   background-color: rgb(253, 130, 130) !important;
 }
} */

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer2 {
  cursor: pointer;
}

.register__permission {
     max-height: 250px;
        padding: 1em;
        margin: 0.5em 0;
        position: relative;
        overflow: auto;
        word-spacing: .5rem;
   
}

.register__permission .list-number {
  color: red
}